/* eslint-disable no-lonely-if */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Layout from '../containers/layout';
import HeaderPage from '../components/HeaderPage';
import WidgetCard from '../components/WidgetCard';
import Paragraph from '../components/Paragraph';
import useMatchMedia from '../hooks/match-media';
import VisualComposer from '../components/VisualComposer';
import ListCardsImage from '../components/ListCardsImage';
import Feed from '../components/Feed';
import CardHero from '../components/CardHero';
import { filterNewsByFormat } from '../actions/news-filter-actions';

const SalaStampa = ({ data, pageContext }) => {
  const [isMobile, setIsMobile] = useState(false);

  const mediaToMatch = '(max-width: 1365px)';
  useMatchMedia(setIsMobile, mediaToMatch);

  const {
    title, label, widgetCard, seoMetaTags
  } = data.datoCmsSalaStampa; // HeaderPage
  const { content, subtitle, contentAccordion } = data.datoCmsSalaStampa; // Paragraph
  const { visualComposer } = data.datoCmsSalaStampa; // VisualComposer
  const { newsFeedModularBlock } = data.datoCmsSalaStampa; // Feed
  const { newsHighlight } = newsFeedModularBlock[0] ? newsFeedModularBlock[0] : {}; // CardHero
  const { slug } = data.datoCmsNewsArchive;
  const { defaultRelatedNews } = { defaultRelatedNews: data.allDatoCmsNews.nodes };

  const filteredRelatedNews = defaultRelatedNews.filter((news) => {
    // Filter all news different to the news in highlight
    if (!newsHighlight) return true;
    return news.slug !== newsHighlight.slug;
  });

  const showWidgetCard = widgetCard && isMobile; // Show or not Widget

  const headerProps = {
    title,
    model: label,
    widgetCard,
    isRoot: true
  };

  const paragraphProps = {
    contentAccordion,
    subtitle,
    content,
    marginBottom: 'zero',
    marginTop: 'zero'
  };

  const cardHeroProps = {
    label: newsHighlight ? newsHighlight.topic.name : '',
    title: newsHighlight ? newsHighlight.title : '',
    labelButton: 'Scopri di più',
    image: (newsHighlight && newsHighlight.coverImage) ? newsHighlight.coverImage.fluid : '',
    alt: (newsHighlight && newsHighlight.coverImage) ? newsHighlight.coverImage.alt : '',
    url: newsHighlight ? `/${slug}/${newsHighlight.slug}` : ''
  };

  const feedProps = {
    title: 'salaStampa',
    url: slug,
    withHighlight: !!newsHighlight,
    labelLink: 'Vedi tutti',
    marginBottom: 'zero',
    marginTop: 'zero',
    hasPadding: true
  };

  const wrappedFeedProps = {
    feedProps,
    cardHeroProps,
    newsHighlight,
    newsFeedModularBlock,
    defaultRelatedNews: filteredRelatedNews.slice(0, 9)
  };

  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      canonical={pageContext.canonical}
    >
      <HeaderPage {...headerProps} />
      <div className="page-wrapper">
        { showWidgetCard && <WidgetCard widgetCard={widgetCard} /> }
        { content && (
        <div className="page-wrapper__paragraph">
          <Paragraph {...paragraphProps} />
        </div>
        )}
      </div>
      <div className="page__archive-news">
        <WrappedFeed {...wrappedFeedProps} />
      </div>
      { visualComposer && <VisualComposer components={visualComposer} /> }
    </Layout>
  );
};

const FeedContainer = ({
  feedProps,
  cardHeroProps,
  newsHighlight,
  newsFeedModularBlock,
  dispatch,
  defaultRelatedNews
}) => {
  const [cards, setCards] = useState();
  const post = newsFeedModularBlock[0] ? newsFeedModularBlock[0].post : [];

  useEffect(() => {
    if (post.length > 0) { // At least 1 custom post
      if (newsHighlight) {
        const newCards = [newsHighlight, ...post];
        setCards(newCards);
      } else {
        setCards(post);
      }
    } else { // Mount the default related news
      if (newsHighlight) {
        const newCards = [newsHighlight, ...defaultRelatedNews];
        setCards(newCards);
      } else {
        setCards(defaultRelatedNews);
      }
    }
  }, []);

  const handleArchiveClick = () => {
    const filter = {
      label: 'Comunicati stampa',
      value: 'comunicati-stampa'
    };
    dispatch(filterNewsByFormat(filter));
  };

  return (
    <Feed {...feedProps} handleArchiveClick={handleArchiveClick}>
      { newsHighlight && <CardHero {...cardHeroProps} /> }
      <ListCardsImage isFeed cards={cards} />
    </Feed>
  );
};

FeedContainer.defaultProps = {
  newsFeedModularBlock: []
};

const WrappedFeed = connect(null)(FeedContainer);

export const SALA_STAMPA_QUERY = graphql`
  query salaStampa {
    datoCmsSalaStampa {
      id
      title
      slug
      subtitle
      label
      content
      contentAccordion
      visualComposer {
        ...BannerBigImage
        ...BannerText
        ...CenteredNumbers
        ...Numbers
        ...Spacing
      }
      seoMeta {
        title
        description
      }
      seoMetaTags {
        tags
      }
      newsFeedModularBlock {
        ...NewsFeedModularBlock
      }
      widgetCard {
        id
        title
        description
        colorTheme
        email
        phone
        iconImageModularContent {
          ... on DatoCmsIcon {
            id
            iconType
          }
          ... on DatoCmsImage {
            id
            imageType {
              alt
              url
            }
          }
        }
        buttonModularContent {
          labelButton {
            value
          }
          url
        }
      }
    }
    datoCmsNewsArchive {
      slug
    }
    allDatoCmsNews (
      limit: 10,
      filter: {
        format: {
          slug: {
            eq: "comunicati-stampa"
          }
        }
      },
      sort: {fields: publicationDate, order: DESC}
    ) {
      nodes {
        slug
        title
        paragraph
        coverImage {
          alt
          fluid(
            imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
            sizes: "(max-width: 1920px) 300px"
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        format {
          id
          name
          slug
        }
        topic {
          id
          slug
          name
          categoryNews {
            id
            slug
            name
          }
        }
        publicationDate
      }
    }
  }
`;

export default SalaStampa;
